import React from "react";
import { Link as GatsbyLink } from "gatsby";

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  const external = /^((\w*?:)|(\/\/))/.test(to);
  // if string begins with a word followed by : or with a double forward slash
  // then assume it's an external link and use <a>. Otherwise, use Gatsby Link.
  return <>
    { (external)
      ? (<a href={to} target="_blank" {...other}>
        {children}
      </a>)
      : (<GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>)
    }
  </>;
};

export default Link;
