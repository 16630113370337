import React, {useState, useEffect} from "react";
import MenuItem from "./menu-item.js";

const NavMenu = props => {
	const [nav, setNav] = useState([]);
	const findParent = (nav, parentId) => {
		for (let item of nav) {
			if (item.id === parentId) {
				return item;
			}
			if (item.children.length) {
				const parent = findParent(item.children, parentId);
				if (parent) {
					return parent;
				}
			}
		}
		return false;
	};
	const fillNav = () => {
		let nav = [];
		try {
			props.nav.edges[0].node.menuItems.nodes.forEach(item => {
				item.children = [];
				if (!item.parentId) {
					nav.push(item);
					return;
				}
				const parent = findParent(nav, item.parentId);
				if (parent && parent.children) {
					parent.children.push(item);
				}
			});
			setNav(nav);
		} catch (e) {
			console.warn("empty navigation: " + props.label); // eslint-disable-line
		}
	};
	if (typeof window === "undefined") {
		fillNav();
	} else {
		useEffect(fillNav, []);
	}
	if (!nav.length) {
		return <></>;
	}
	return (
		<nav id={props.id} role='navigation' aria-label={props.label} className={(props.className || "") + (props.visible ? " expanded" : " collapsed")}>
			<ul itemScope='' itemType='http://www.schema.org/SiteNavigationElement'>
				{nav.map(item =>
					item.databaseId === 774 ? (
						<li class='wp-block-button is-style-button-border'>
							<a href='https://www.groupe-fivalec.com/mon-compte-fivalec/' id='menu-item-774' itemprop='url' title='Mon compte'>
								Mon compte
							</a>
						</li>
					) : (
						<MenuItem
							containerClassName={props.containerClassName}
							key={item.id}
							path={item.path}
							label={item.label}
							id={item.id}
							databaseId={item.databaseId}
							children={item.children} // eslint-disable-line
							dropdown={props.dropdown}
						/>
					)
				)}
			</ul>
		</nav>
	);
};
export default NavMenu;
