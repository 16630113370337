import React, {useState} from "react";
import { useStaticQuery, graphql } from "gatsby";
import NavMenu from "./header/menu/nav-menu.js";
import QuickLinks from "./header/quicklinks.js";
import Logo from "./header/logo.js";
import Hamburger from "./header/hamburger.js";
import loadable from "@loadable/component";
const SearchForm = loadable(() => import("./header/search.js"));

const Header = ({ menuStateUpdate }) => {
  const props = useStaticQuery(query);
  const logo = props.wp.themeGeneralSettings.themeSettings.siteLogo.localFile;
  const alternativeLogo = props.wp.themeGeneralSettings.themeSettings.siteLogoAlternative?.localFile;
  const title = props.wp.generalSettings.title;
  const description = props.wp.generalSettings.description;
  const [isMenuVisible, toggleMenu] = useState(false);
  return (
    <header id="header">
      <div>
        <QuickLinks />
        {
          props.site.siteMetadata.useSiteCatchphrase
          ? <div className="logo">
              <Logo logo={ logo } title={ title } />
              <p>{ description }</p>
            </div>
          : <Logo logo={ logo } title={ title } />
        }
        <Hamburger
          id="mainNav"
          onOpen={() => {
            menuStateUpdate(true);
            toggleMenu(true);
          }}
          onClose={() => {
            menuStateUpdate(false);
            toggleMenu(false); 
          }}
        />
        <div className="menus">
          <NavMenu dropdown="hover" nav={ props.mainMenu } id="mainMenu" label="Menu de navigation principal" visible={ isMenuVisible }/>
          { 
            props.site.siteMetadata.hasSearch
              ? <SearchForm />
              : <></>
          }
          { 
            props.wp.themeGeneralSettings.themeSettings.siteLogoAlternative
              ? <Logo logo={ alternativeLogo } title={ title } />
              : <></>
          }
          <NavMenu nav={ props.secondaryMenu } id="secondaryMenu" label="Menu de navigation secondaire" visible={ isMenuVisible } containerClassName="wp-block-button is-style-button-border"/>
        </div>
      </div>
    </header>
  );
};

export const query = graphql`
  query Header {
    site {
      siteMetadata {
        hasSearch
        useSiteCatchphrase
      }
    }
    wp {
      themeGeneralSettings {
        themeSettings {
          siteLogo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 270)
              }
            }
          }
          siteLogoAlternative {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 130)
              }
            }
          }
        }
      }
      generalSettings {
        title
        description
      }
    }
    mainMenu: allWpMenu(filter: {locations: {in: PRIMARY_NAVIGATION}}) {
      edges {
        node {
          menuItems {
            nodes {
              id
              path
              label
              parentId
              databaseId
            }
          }
        }
      }
    }
    secondaryMenu: allWpMenu(filter: {locations: {in: SECONDARY_NAVIGATION}}) {
      edges {
        node {
          menuItems {
            nodes {
              id
              path
              label
              parentId
              databaseId
            }
          }
        }
      }
    }
  }
`;
export default Header;
